import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NetworkService } from 'app/services/network.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Injectable({
    providedIn: 'root'
})

export class BadgesService {

    public badges: object = {};

    constructor(private networkService: NetworkService, private fuseConfigService: FuseConfigService) {}

    fetchBadges() {
        return this.networkService.get('organization/badge');
    }
}